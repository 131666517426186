
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CheckCommonLogin from "@/utils/CheckCommonLogin";

@Component({
  name: "NotificationStopSuccess",
  components: {
    Header,
    Footer,
  },
})
export default class NotificationStopSuccess extends CheckCommonLogin {
  private toMyPage() {
    this.$router.push({ name: "MyPage" });
  }
}
